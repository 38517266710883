import $ from 'jquery';
import AOS from 'aos';
import mixitup from 'mixitup';
import 'popper.js';
import 'bootstrap';
import 'magnific-popup';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade, Lazy } from 'swiper/core';
import counterUp from 'counterup2';
import 'waypoints/lib/noframework.waypoints.min.js';

window.$ = $; // Make jQuery globally available

$(() => {

  // $('.photo-gallery').magnificPopup({ 
  //   delegate: '.photo-gallery-top, .photo-gallery-thumbnail',
  //   type: 'image',
  //   gallery: {
  //     enabled: true
  //   }
  // });


  $('.clickable').on('click', function (event) {
    const href = $(this).find('a').attr('href');
    const target = $(this).find('a').attr('target');
    if (target == "_blank") {
       window.open(href, '_blank');
    } else {
       window.location = href;
    }
  });

  // Configure Swiper to use modules
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  // Init logo gallery swiper
  const swiper = new Swiper('.swiper', {
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000
    },
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });
  
  // Init animate on scroll
  AOS.init({
    duration: 500,
    mirror: true,
    //once: true
  });

  document.querySelectorAll('.counter').forEach(function(element){
	new Waypoint( {
		element,
		handler: function() { 
			counterUp(element, {
			  duration: 1000,
			  delay: 16,
			}); 
			this.destroy()
		},
		offset: 'bottom-in-view',
	  });  
  });

  document.querySelector('#main-navigation').addEventListener('click',function(){
    if(document.querySelector('nav .navigation-container').classList.contains('is-active')) {
        document.querySelector('nav .navigation-container').classList.remove('is-active');
    } else {
        document.querySelector('nav .navigation-container').classList.add('is-active');
    }
  });

    // Initial Mixitup
  if(document.querySelectorAll('.cases-list_container').length) {
      //window.mixitup('.cases-list_container');
      var mixer = mixitup('.cases-list_container', {
          animation: {
              duration: 300
          }
      });
      
  }

  if(document.querySelectorAll('.actueel-list_container').length) {
      //window.mixitup('.actueel-list_container');
      var mixer = mixitup('.actueel-list_container', {
          animation: {
              duration: 300
          }
      });
  }
  // Filters
  // document.querySelectorAll('.filter-item').forEach(function(filter){
  //   filter.addEventListener('click',function(e){
        
  //       document.querySelectorAll('.filter-item').forEach(function(item){item.classList.remove('active')});
  //       if(!e.target.classList.contains('active')) {
  //           e.target.classList.add("active");
  //       }   
  //   });
  // });

  document.querySelectorAll('.single-news-card_container').forEach(function(card){
    card.addEventListener('click',function(e){
        if( e.target.querySelector('a') ) {
          window.location = e.target.querySelector('a').href;
        }
    });

  });



});

// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header.header').outerHeight() - 50;

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 200);

function hasScrolled() {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        //alert('scroll down');

        $('header.header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        //alert('scroll up');
        if(st + $(window).height() < $(document).height()) {
            $('header.header').removeClass('nav-up').addClass('nav-down');
        }
    }
    
    lastScrollTop = st;
}

